var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","persistent":"","transition":"dialog-bottom-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"id":"grid-search-settings-edit-btn","color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Edit ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"grid-search-settings_main-display"},[_c('div',{staticClass:"grid-search-settings_main-display"},[_c('v-toolbar',{staticClass:"grid-search-settings-edit-toolbar",attrs:{"color":"primary","dark":""}},[_c('GridSearchSettingEditWizardCloseDialog',{on:{"closeWizard":_vm.closeDialog}}),_c('v-toolbar-title',[_vm._v(" Edit Grid Settings ")])],1),_c('div',{staticClass:"d-flex-column px-5"},[_c('h2',{staticClass:"font-weight-regular mt-6"},[_vm._v(" Configure your Grid Search Headers ")]),_c('h5',{staticClass:"font-weight-regular mb-3"},[_vm._v(" Click to select any fields you wish to be available in your grid settings. Only published templates will show. ")]),_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(" Choose Template: ")]),_c('v-autocomplete',{staticClass:"grid-settings-search-edit-wizard__template-select ml-5 mr-10 mt-5",attrs:{"id":"grid-settings-search-edit-wizard__template-select","label":"Select the template","items":_vm.templateNameField.items(),"loading":_vm.isLoading},on:{"change":_vm.onTemplateSelected},model:{value:(_vm.templateSelected),callback:function ($$v) {_vm.templateSelected=$$v},expression:"templateSelected"}})],1)],1),_c('div',{staticClass:"d-flex align-center py-10 px-1"},[_c('div',{staticClass:"grid-settings-search-edit-wizard__nav-panel px-5"},[_c('h3',[_vm._v(" Choose Your Fields: "),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"slot":"activator","id":"grid-settings__helper-icon","small":""},slot:"activator"},'v-icon',attrs,false),on),[_vm._v(" mdi-information ")])]}}])},[_c('span',[_vm._v("Job List, File Upload and Table fields aren't selectable for grid settings.")])])],1),_c('v-card',{staticClass:"grid-settings-search-edit-wizard__section-panel-card fill-height"},[_c('v-data-table',{staticClass:"grid-settings-search-edit-wizard__table mt-2 flex-column justify-space-between",attrs:{"headers":_vm.gridSettingsHeaders,"items":_vm.defaultGridTemplateSettingItems,"loading":_vm.isLoading,"scrollable":"","hide-default-footer":"","disable-sort":"","fixed-header":"","height":"55vh","disable-pagination":"","item-class":_vm.itemRowBackground},on:{"click:row":_vm.addTemplateGridSetting}})],1)],1),_c('div',{staticClass:"grid-settings-search-edit-wizard__section-panel fill-height px-5"},[_c('h3',[_vm._v("Available Grid Fields: "+_vm._s(_vm.gridSettingsSelectedAmount)+"/100 items")]),_c('v-card',{staticClass:"grid-settings-search-edit-wizard__section-panel-card"},[_c('v-data-table',{staticClass:"grid-settings-search-edit-wizard__table mt-2 flex-column justify-space-between",attrs:{"headers":_vm.gridSettingsSelectionHeaders,"items":_vm.gridSettingsSelectionData,"hide-default-footer":"","disable-sort":"","fixed-header":"","height":"55vh","disable-pagination":""},scopedSlots:_vm._u([{key:"header.actions",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"d-sr-only"},[_vm._v(_vm._s(header.text))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-space-around justify-lg-start"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"id":"grid-settings__item-delete-btn","icon":""},on:{"click":function($event){return _vm.onDeleteTemplateGridSetting(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete")])])],1)]}}],null,true)})],1)],1)])],1),_c('v-divider',{staticClass:"mb-3"}),_c('div',{staticClass:"template-mapping-wizard__wrapper-navigation d-flex justify-end"},[_c('v-btn',{staticClass:"template-mapping-wizard__next-btn align-self-end ma-2",attrs:{"id":"grid-settings__save-btn","color":"primary","disabled":!_vm.gridSettingsSelectionData.length || _vm.gridSettingsSelectedAmount >= 100},on:{"click":_vm.saveAdminGridTemplateSettings}},[_vm._v(" Save ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }