
































































































































































import {
  defineComponent, ref, onMounted, computed,
} from '@vue/composition-api';
import Store from '@/store';
import { ITemplateSearchRecord, IVComboboxItem, IGridSettingsSelectionField } from '@/types';
import GridSearchSettingEditWizardCloseDialog from './GridSearchSettingEditWizardCloseDialog.vue';

export default defineComponent({
  components: {
    GridSearchSettingEditWizardCloseDialog,
  },

  setup() {
    onMounted(() => {
      Store.dispatch('templateList/getPublishedTemplatesData');
      Store.dispatch('templateList/loadDefaultGridSettingTemplateFieldsSelections');
    });

    const templateSelected = ref();
    const dialog = ref(false);
    const closeDialog = () => {
      templateSelected.value = '';
      Store.dispatch('templateList/setDefaultGridSettingTemplateFields', []);
      dialog.value = false;
    };

    const gridSettingsHeaders = [
      { text: 'Field Name', align: 'start', value: 'customFieldName' },
      { text: 'Section Name', value: 'sectionName' },
      { text: 'Field Type', value: 'customFieldType' },
    ];

    const gridSettingsSelectionHeaders = [
      { text: 'Field Name', align: 'start', value: 'customFieldName' },
      { text: 'Template', value: 'templateName' },
      { text: 'Field Type', value: 'customFieldType' },
      { text: 'actions', value: 'actions' },
    ];

    const isLoading = computed(
      () => Store.getters['templateList/getLoading'] as boolean,
    );

    const onDeleteTemplateGridSetting = (value: IGridSettingsSelectionField) => {
      Store.dispatch('templateList/removeDefaultGridSetting', value.customFieldId);
    };

    const defaultGridTemplateSettingItems = computed(
      () => Store.getters['templateList/getDefaultGridSettingTemplateFields'] as IGridSettingsSelectionField[],
    );

    const gridSettingsSelectionData = computed(() => Store.getters['templateList/getDefaultGridSettingTemplateFieldsSelections'] as IGridSettingsSelectionField[]);

    /*
    * This class checks if the template field has been added to the default grid templates array.
    * If it is added the css changes to show the item is added.
    */
    const itemRowBackground = (item: IGridSettingsSelectionField) => (gridSettingsSelectionData.value.some((selectedItems) => (selectedItems.customFieldId === item.customFieldId)) ? 'grid-settings-search-edit-wizard__selected-item' : 'grid-settings-search-edit-wizard__item');

    const templateNameField = {
      items: (): IVComboboxItem[] => {
        const items = Store.getters['templateList/getPublishedTemplatesData'] as ITemplateSearchRecord[];
        return items.map((temp) => ({
          text: temp.identifier,
          value: temp.id,
        }));
      },
    };

    /**
     *  @summary function adds the grid setting if field hasn't already been added and it isnt loading
     *  @author Rory Harford
     */
    const addTemplateGridSetting = (value : IGridSettingsSelectionField) => {
      if (gridSettingsSelectionData.value.some((selectedItems) => (selectedItems.customFieldId === value.customFieldId)) || isLoading.value) return;
      Store.dispatch('templateList/addDefaultGridSetting', value);
    };

    /**
     * On template name field input update work order template details in store
     * @param {ITemplateSearchRecord} value Field value
     */
    const onTemplateSelected = (workOrderTemplate: string) => {
      const templateId = workOrderTemplate;
      // set ID for loading fields in general store
      Store.dispatch('templateList/setTemplateFieldsGridSettings', templateId);
    };

    /**
     * gridSettingsSelectedAmount checks how many fields have been added that are different name and field type
     */
    const gridSettingsSelectedAmount = computed(() => {
      const addedSettings = [] as IGridSettingsSelectionField[];
      gridSettingsSelectionData.value.forEach((item) => {
        const gridSettingChecker = addedSettings.find((alreadyAdded) => alreadyAdded.customFieldName === item.customFieldName && alreadyAdded.customFieldType === item.customFieldType);
        if (!gridSettingChecker) addedSettings.push(item);
      });
      return addedSettings.length;
    });

    /**
     *  @summary function saves the admin grid template settings
     *  @author Rory Harford
     */
    const saveAdminGridTemplateSettings = () => {
      Store.dispatch('templateList/saveAdminGridTemplateSettings');
      closeDialog();
    };

    return {
      gridSettingsSelectionHeaders,
      gridSettingsHeaders,
      onDeleteTemplateGridSetting,
      dialog,
      closeDialog,
      templateNameField,
      isLoading,
      onTemplateSelected,
      defaultGridTemplateSettingItems,
      gridSettingsSelectionData,
      addTemplateGridSetting,
      itemRowBackground,
      gridSettingsSelectedAmount,
      saveAdminGridTemplateSettings,
      templateSelected,
    };
  },
});
