





























import { defineComponent, computed } from '@vue/composition-api';
import Store from '@/store';
import { IGridSettingsSelectionField } from '@/types';
import GridSearchSettingEditWizard from '../components/GridSearchSettingsWizard/GridSearchSettingEditWizard.vue';

export default defineComponent({

  components: {
    GridSearchSettingEditWizard,
  },
  setup() {
    const gridSettingsHeaders = [
      { text: 'Field Name', align: 'start', value: 'customFieldName' },
      { text: 'Section Name', value: 'sectionName' },
      { text: 'Template', value: 'templateName' },
      { text: 'Field Type', value: 'customFieldType' },
    ];

    const gridSettingsData = computed(() => Store.getters['templateList/getDefaultGridSettingTemplateFieldsSelections'] as IGridSettingsSelectionField[]);

    const isLoading = computed(
      () => Store.getters['templateList/getLoading'] as boolean,
    );

    return {
      gridSettingsHeaders,
      gridSettingsData,
      isLoading,
    };
  },
});
