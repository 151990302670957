




















































import { defineComponent, ref } from '@vue/composition-api';

export default defineComponent({
  emit: ['closeWizard'],

  setup(_props, { emit }) {
    const dialog = ref(false);

    /**
       * Closes dialog
       * @param {boolean} [closeWizard=false] Closes wizard if true
       */
    const closeDialog = (closeWizard = false) => {
      dialog.value = false;
      if (closeWizard) emit('closeWizard');
    };

    return { dialog, closeDialog };
  },
});
